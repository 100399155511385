import React, { useState, useEffect, useRef } from "react";
import { PriceChart } from "../Chart/PriceChart";
import { PriceTable } from "../Table/PriceTable";
import { MobileSidebarMenu } from "../SidebarMenu/MobileSidebarMenu";
import { TradeTableMobile } from "../Table/TradeTableMobile";
import { DeletedMobileDialog } from "../Dialog/DeletedMobileDialog";
import { PriceTableMobileDialog } from "../Dialog/PriceTableMobileDialog";
import { MobileTopHeader } from "../TopHeader/MobileTopHeader";
import { NewOrderDialog } from "../Dialog/NewOrderDialog";
import { MobileDownHeaderMenu } from "../MobileDownHeader/MobileDownHeaderMenu";
import { NewOrderMobileDialog } from "../Dialog/NewOrderMobileDialog";
import { useData } from "../../context/DataContext";
import axios from "axios";

const useInterval = (callback, delay) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);

  return intervalRef;
};

export function MobileTradingPage() {
  const [newOrderDialogVisible, setNewOrderDialogVisible] = useState(false);
  const [sidebarMenu, setSidebarMenu] = useState(false);
  const [visibleDeleteSidebar, setVisibleDeleteSidebar] = useState(false);
  const [visiblePriceTableSidebar, setVisiblePriceTableSidebar] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemSymbol, setSelectedItemSymbol] = useState(null);
  const [FreeMarginMobilePublic, setFreeMarginMobilePublic] = useState(0);
  const [FreeMarginLevelPublic, setFreeMarginLevelPublic] = useState(0);

  const { componentShow, symbolType, setSymbolType } = useData();

  const fixedFooterStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 10, // Keeps it above other content
  };

  const [FreeMargin, setFreeMargin] = useState(0);
  const [Equity, setEquity] = useState(0);
  const [Level, setLevel] = useState("0.00%");
  const [Margin, setMargin] = useState(0);
  const [profitValue, setProfitValue] = useState(0);
  const { addRecord, deleteRecord, setTotoalBalance, TotalBalance } = useData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteCheck, setDeleteCheck] = useState(0);
  // const [isOpen, setIsOpen] = useState(null);
  const [isOpen, setIsOpen] = useState("open");
  const startDateValue = new Date();
  const startDate = new Date(
    startDateValue.setDate(startDateValue.getDate() - 1)
  )
    .toISOString()
    .split("T")[0];
  const endDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const fetchProfileDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://makassab.com/trading_platform/backend/dashboard/customer/getProfileDetails.php"
        );
        setTotoalBalance(response.data.data[0].totalBalance);
      } catch (error) {
        console.error("Error fetching profile details:", error);
      } finally {
        setLoading(false); // Data fetched successfully, set loading to false
      }
    };

    fetchProfileDetails();
  }, [addRecord, deleteRecord, deleteCheck]);

  const fetchDataValue = async (symbol, currencyType) => {
    const apiURL =
      currencyType === "Forex"
        ? `https://api.polygon.io/v1/conversion/USD/${symbol.substring(
            3
          )}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : currencyType === "Crypto"
        ? `https://api.binance.com/api/v3/ticker/bookTicker?symbol=${symbol}USDT`
        : currencyType === "Metals"
        ? `https://api.polygon.io/v1/conversion/USD/${symbol}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : currencyType === "Indices"
        ? `https://api.polygon.io/v2/aggs/ticker/I:${symbol}/range/1/second/${startDate}/${endDate}?sort=desc&limit=1&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : `https://api.polygon.io/v2/last/nbbo/${symbol}?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;

    try {
      const response = await fetch(apiURL);
      const responseData = await response.json();

      return {
        ask:
          currencyType === "Forex"
            ? responseData.last.ask.toFixed(5)
            : currencyType === "Crypto"
            ? parseFloat(responseData.askPrice).toFixed(5)
            : currencyType === "Metals"
            ? responseData.last.ask.toFixed(5)
            : currencyType === "Indices"
            ? responseData.results[0].h.toFixed(5)
            : responseData.results.P.toFixed(5),
        bid:
          currencyType === "Forex"
            ? responseData.last.bid.toFixed(5)
            : currencyType === "Crypto"
            ? parseFloat(responseData.bidPrice).toFixed(5)
            : currencyType === "Metals"
            ? responseData.last.bid.toFixed(5)
            : currencyType === "Indices"
            ? responseData.results[0].l.toFixed(5)
            : responseData.results.p.toFixed(5),
      };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { ask: null, bid: null };
    }
  };

  const fetchRecords = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://makassab.com/trading_platform/backend/platform/getTrades.php"
      );
      if (response.data.status === "success") {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const updatedData = await Promise.all(
        data.map(async (data) => {
          // Run fetchDataValue and fetchProfitDetails concurrently
          const [dataValues, profitDetails] = await Promise.all([
            fetchDataValue(data.symbol, data.currencyType),
            fetchProfitDetails(data.ticket),
          ]);

          const { ask, bid } = dataValues;
          const { lots, leverage } = profitDetails;

          const profit =
            data.type === "Buy"
              ? (bid - data?.orginalPrice) * lots * leverage * data?.volume
              : (data?.orginalPrice - ask) * lots * leverage * data?.volume;

          // Check if bid is equal to stoploss or takeprofit
          if (bid && data) {
            if (bid <= data.stopLoss || bid <= data.takeProfit) {
              await handleDelete({
                ...data,
                ask: 0,
                bid: bid,
                profit: isNaN(profit) ? "" : profit.toFixed(5),
              });
              return null; // Skip returning the data
            }
          }

          return {
            ...data,
            ask: data.type === "Sell" ? ask : data.orginalPrice,
            bid: data.type === "Buy" ? bid : data.orginalPrice,
            profit: isNaN(profit) ? "" : profit.toFixed(5),
          };
        })
      );

      // Filter out any null values returned due to stoploss/takeprofit triggering deletion
      setData(updatedData.filter((item) => item !== null));
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  };

  const handleDelete = async (orderToDelete) => {
    setLoading(true);
    // Optimistically update the state
    const updatedData = data.filter(
      (record) => record.ticket !== orderToDelete.ticket
    );
    setData(updatedData);

    let finalBidValue = orderToDelete?.bid;
    let finalAskValue = orderToDelete?.ask;

    const deletedRecord = {
      ticket: orderToDelete.ticket,
      lastPrice: orderToDelete.type === "Buy" ? finalBidValue : finalAskValue,
      profit: orderToDelete.profit,
    };

    try {
      const response = await fetch(
        "https://makassab.com/trading_platform/backend/platform/updateTrades.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(deletedRecord),
        }
      );

      const data = await response.json();

      if (data.status !== "success") {
        // If the server request fails, revert the state change
        setData((prevData) => [...prevData, orderToDelete]);
      } else {
        setDeleteCheck(!deleteCheck);
        fetchRecords();
      }

      setLoading(false);
    } catch (error) {
      console.error("Error updating trades:", error);
      // Revert the state change if there's an error
      setData((prevData) => [...prevData, orderToDelete]);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, [addRecord, deleteRecord]);

  // Use the useInterval hook at the top level of the component
  const delay = isOpen !== "close" ? 2000 : null; // Check if isOpen is specifically "open"

  useInterval(
    () => {
      if (!loading) {
        fetchData();
      }
    },
    delay,
    loading
  );

  useEffect(() => {
    fetchData(); // Fetch data once when component mounts or symbol list changes
  }, [isOpen]);

  useEffect(() => {
    const fetchmoneyDetails = async () => {
      try {
        const response = await axios.get(
          "https://makassab.com/trading_platform/backend/platform/getMargins.php"
        );
        if (response.data.status === "success") {
          setMargin(response.data.totalMargin.toFixed(4));
        }
      } catch (error) {
        console.error("Error fetching profile details:", error);
      }
    };

    fetchmoneyDetails();
  }, []);

  const fetchProfitDetails = async (ticketNumber) => {
    try {
      const response = await fetch(
        "https://makassab.com/trading_platform/backend/platform/getProfit.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ticketNumber }),
        }
      );

      const result = await response.json();
      if (result.status === "success") {
        return { lots: result.lots, leverage: result.leverage };
      } else {
        console.error("Error fetching profit details:", result);
        return { lots: 0, leverage: 0 }; // Fallback in case of failure
      }
    } catch (error) {
      console.error("Fetch error:", error);
      return { lots: 0, leverage: 0 }; // Fallback in case of error
    }
  };

  useEffect(() => {
    const calculateProfit = async () => {
      try {
        // Fetch lots and leverage for all items in data
        // Fetch lots and leverage for all items in data
        const profitPromises = data.map(async (value) => {
          const { lots, leverage } = await fetchProfitDetails(value.ticket);

          // Calculate the profit for each entry based on the fetched lots and leverage
          const profit =
            value.type === "Buy"
              ? (value.bid - value.orginalPrice) *
                lots *
                leverage *
                value.volume
              : (value.orginalPrice - value.ask) *
                lots *
                leverage *
                value.volume;

          return profit;
        });

        // Resolve all promises and calculate the total profit
        const allProfits = await Promise.all(profitPromises);
        const updatedProfit = allProfits.reduce(
          (acc, profit) => acc + profit,
          0
        );

        setProfitValue(updatedProfit.toFixed(4));
        let calculatedEquity = TotalBalance + updatedProfit;
        setEquity(isNaN(calculatedEquity) ? " " : calculatedEquity.toFixed(4));
        let calculationFreeMargin = calculatedEquity - Margin;
        setFreeMargin(
          isNaN(calculationFreeMargin) ? " " : calculationFreeMargin.toFixed(4)
        );
        setFreeMarginMobilePublic(
          isNaN(calculationFreeMargin) ? " " : calculationFreeMargin.toFixed(4)
        );

        // Check if calculatedEquity and marginValue are valid numbers
        if (calculatedEquity > 0 && Margin > 0) {
          let level = ((calculatedEquity / Margin) * 100).toFixed(2);
          setLevel(level);
          setFreeMarginLevelPublic(level);
        } else {
          setLevel(" ");
          setFreeMargin(" ");
          console.error("Invalid value for calculatedEquity or marginValue");
        }
      } catch (error) {
        console.error("Error calculating profit:", error);
      }
    };

    calculateProfit();
  }, [data, addRecord]);

  return (
    <div className="flex flex-column">
      {componentShow === "chart" && (
        <MobileTopHeader
          setSidebarMenu={setSidebarMenu}
          setNewOrderDialogVisible={setNewOrderDialogVisible}
        />
      )}
      <div className="flex flex-row">
        {sidebarMenu && componentShow === "chart" && (
          <div className="flex">
            <MobileSidebarMenu />
          </div>
        )}
        <div style={{ height: "100%", width: "100%" }}>
          <div style={{ width: "100%", height: "100%" }}>
            {componentShow === "chart" && (
              <div>
                <PriceChart />
              </div>
            )}
            {componentShow === "priceTable" && (
              <div>
                <PriceTableMobileDialog
                  visible={visiblePriceTableSidebar}
                  setVisible={setVisiblePriceTableSidebar}
                  // setNewOrderDialogVisible={setNewOrderDialogVisible}
                  selectedItemSymbol={selectedItemSymbol}
                />
                <PriceTable
                  mobile={true}
                  setVisible={setVisiblePriceTableSidebar}
                  setSelectedItemSymbol={setSelectedItemSymbol} // Pass setter function here
                />
              </div>
            )}
            {componentShow === "tradingTable" && (
              <div>
                <DeletedMobileDialog
                  visible={visibleDeleteSidebar}
                  setVisible={setVisibleDeleteSidebar}
                  selectedItem={selectedItem} // Pass selected item here
                  setSelectedItem={setSelectedItem}
                />
                <TradeTableMobile
                  setVisible={setVisibleDeleteSidebar}
                  setSelectedItem={setSelectedItem}
                  loading={loading}
                  profitValue={profitValue}
                  TotalBalance={TotalBalance}
                  FreeMargin={FreeMargin}
                  Equity={Equity}
                  Level={Level}
                  Margin={Margin}
                  data={data}
                />
              </div>
            )}
            {componentShow === "NewOrderMobileDialog" && (
              <div>
                <NewOrderMobileDialog
                  selectedItemSymbol={selectedItemSymbol}
                  setVisiblePriceTable={setVisiblePriceTableSidebar}
                  setVisibleDeleteSidebar={setVisibleDeleteSidebar}
                  FreeMargin={FreeMargin}
                  Level={Level}
                  symbolType={symbolType}
                  setSymbolType={setSymbolType}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={fixedFooterStyle}>
        <MobileDownHeaderMenu />
      </div>
    </div>
  );
}
