import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useData } from "../../context/DataContext";
import "./newOrderDialog.css";

export function NewOrderDialog({ visible, setVisible, fullSymbolData }) {
  const [selectedWay, setSelectedWay] = useState(null);
  const [buySellLimitBoxShow, setBuySellLimitBoxShow] = useState(false);
  const [volume, setVolume] = useState(0.01);
  const [stopLoss, setStopLoss] = useState(0);
  const [takeProfit, setTakeProfit] = useState(0);
  const [price, setPrice] = useState(0);
  const [stopLimitPrice, setStopLimitPrice] = useState(0);
  const [comment, setComment] = useState("");
  const [bidColor, setBidColor] = useState("bg-blue-100");
  const [askColor, setAskColor] = useState("bg-blue-100");
  const [isOpenpMarket, setIsOpenMarket] = useState(true);
  // const [isOpen, setIsOpen] = useState(null);
  const [isOpen, setIsOpen] = useState("open");
  const [recordStatus, setRecordStatus] = useState(null);
  const {
    selectedRow,
    setAddRecord,
    addRecord,
    serverURL,
    FreeMarginPublic,
    FreeMarginLevelPublic,
    symbolType,
  } = useData();
  const [firstStopLossClick, setFirstStopLossClick] = useState(true);
  const [firstTackProfitClick, setFirstTackProfitClick] = useState(true);
  const [disableSellButton, setDisableSellButton] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  // const fetchMarketStatus = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://api.polygon.io/v1/marketstatus/now?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
  //     );
  //     const data = await response.json();
  //     setIsOpen(data?.market);
  //   } catch (error) {
  //     console.error("Error fetching symbol list: ", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchMarketStatus();
  // }, [isOpen]);

  const exchangeWay = [
    { name: "Instant Execution", code: "IE" },
    { name: "Buy Limit", code: "BL" },
    { name: "Sell Limit", code: "SL" },
    { name: "Buy Stop", code: "BS" },
    { name: "Sell Stop", code: "SS" },
    { name: "Buy Stop Limit", code: "BSL" },
    { name: "Sell Stop Limit", code: "SSL" },
  ];

  const handleNum = (type, status) => {
    // const { bid, ask } = selectedRow;
    const bid = selectedRow.bid;

    if (status === "decrease") {
      if (type === "volume" && volume >= 0) {
        setVolume(volume - 0.01);
      } else if (type === "stopLoss" && stopLoss >= 0) {
        setDisableSellButton(true);
        if (firstStopLossClick) {
          let value = parseFloat(bid) + 0.00003;
          setStopLoss(value);
          setFirstStopLossClick(false);
        } else {
          if (stopLoss > 0) {
            setStopLoss(stopLoss - 0.00001);
          }
        }
      } else if (type === "takeProfit" && takeProfit >= 0) {
        setDisableSellButton(true);

        if (firstTackProfitClick) {
          let value = parseFloat(bid) + 0.003;
          setTakeProfit(value);
          setFirstTackProfitClick(false);
        } else {
          if (takeProfit > 0) {
            setTakeProfit(takeProfit - 0.00001);
          }
        }
      } else if (type === "price" && price >= 0) {
        setPrice();
      } else if (type === "stopLimitPrice" && stopLimitPrice >= 0) {
        setStopLimitPrice();
      }
    } else {
      if (type === "volume" && volume > 0) {
        setVolume(volume + 0.01);
      } else if (type === "stopLoss" && stopLoss >= 0) {
        setDisableSellButton(true);
        if (firstStopLossClick) {
          let value = parseFloat(bid) + 0.00003;
          setStopLoss(value);
          setFirstStopLossClick(false);
        } else {
          setStopLoss(stopLoss + 0.00001);
        }
      } else if (type === "takeProfit" && takeProfit >= 0) {
        setDisableSellButton(true);

        if (firstTackProfitClick) {
          let value = parseFloat(bid) + 0.003;
          setTakeProfit(value);
          setFirstTackProfitClick(false);
        } else {
          setTakeProfit(takeProfit + 0.00001);
        }
      } else if (type === "price" && price >= 0) {
        setPrice();
      } else if (type === "stopLimitPrice" && stopLimitPrice >= 0) {
        setStopLimitPrice();
      }
    }
  };

  const handleOrderSell = (action) => {
    let bid = selectedRow?.bid;

    // Construct the request payload
    const payloadSell = {
      fullSymbol: fullSymbolData
        ? selectedRow.fullSymbol
        : symbolType.name === "Forex"
        ? "USD" + selectedRow?.Symbol
        : symbolType.name === "Crypto"
        ? selectedRow?.Symbol
        : symbolType.name === "Metals"
        ? "USD" + selectedRow?.Symbol
        : symbolType.name === "Indices"
        ? selectedRow?.Symbol
        : selectedRow?.Symbol,

      currenciesTypeName: selectedRow.CurrencyType,
      tradestypes: action.toLowerCase(), // Convert to lowercase as per your API requirement
      volume: volume,
      orginalPrice: bid,
      stopLoss: stopLoss,
      takeProfit: takeProfit,
      freeMargin: FreeMarginPublic,
      marginLevel: FreeMarginLevelPublic,
    };

    setRecordStatus(payloadSell);

    if (isOpen !== "closed") {
      // Make the API request
      fetch(`${serverURL}/platform/addTrades.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadSell),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setAddRecord(!addRecord);
            setSelectedWay(null);
            setVolume(0.01);
            setStopLoss(0);
            setTakeProfit(0);
            setComment("");
            setIsOpenMarket(true);
            setVisible(false);
            setFirstTackProfitClick(true);
            setFirstStopLossClick(true);
            setDisableSellButton(false);
          } else if (data.status === "Failure") {
            setMessage(data.message);
            setShowMessage(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setIsOpenMarket(false);
    }

    // // Make the API request
    // fetch(`${serverURL}/platform/addTrades.php`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(payloadSell),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.status == "success") {
    //       setAddRecord(!addRecord);
    //       setSelectedWay(null);
    //       setVolume(0.01);
    //       setStopLoss(0);
    //       setTakeProfit(0);
    //       setComment("");
    //       if (isOpen !== "closed") {
    //         setIsOpenMarket(true);
    //         setVisible(false);
    //       } else {
    //         setIsOpenMarket(false);
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };

  const handleOrderBuy = (action) => {
    let ask = selectedRow?.ask;

    const payload = {
      fullSymbol: fullSymbolData
        ? selectedRow.fullSymbol
        : symbolType.name === "Forex"
        ? "USD" + selectedRow?.Symbol
        : symbolType.name === "Crypto"
        ? selectedRow?.Symbol
        : symbolType.name === "Metals"
        ? "USD" + selectedRow?.Symbol
        : symbolType.name === "Indices"
        ? selectedRow?.Symbol
        : selectedRow?.Symbol,

      currenciesTypeName: selectedRow.CurrencyType,
      tradestypes: action.toLowerCase(), // Convert to lowercase as per your API requirement
      volume: volume,
      orginalPrice: ask,
      stopLoss: stopLoss,
      takeProfit: takeProfit,
      freeMargin: FreeMarginPublic,
      marginLevel: FreeMarginLevelPublic,
    };

    setRecordStatus(payload);

    if (isOpen !== "closed") {
      // Make the API request
      fetch(`${serverURL}/platform/addTrades.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setAddRecord(!addRecord);
            setSelectedWay(null);
            setVolume(0.01);
            setStopLoss(0);
            setTakeProfit(0);
            setComment("");
            setIsOpenMarket(true);
            setVisible(false);
            setFirstTackProfitClick(true);
            setFirstStopLossClick(true);
            setDisableSellButton(false);
          } else if (data.status === "Failure") {
            setMessage(data.message);
            setShowMessage(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setIsOpenMarket(false);
    }

    // // Make the API request
    // fetch(`${serverURL}/platform/addTrades.php`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(payload),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.status === "success") {
    //       setAddRecord(!addRecord);
    //       setSelectedWay(null);
    //       setVolume(0.01);
    //       setStopLoss(0);
    //       setTakeProfit(0);
    //       setComment("");
    //       if (isOpen !== "closed") {
    //         setIsOpenMarket(true);
    //         setVisible(false);
    //       } else {
    //         setIsOpenMarket(false);
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };

  const prevBidRef = useRef(null);
  const prevAskRef = useRef(null);

  useEffect(() => {
    if (selectedRow?.bid !== prevBidRef.current) {
      setBidColor(
        selectedRow?.bid > prevBidRef.current ? "text-blue-400" : "text-red-400"
      );
      prevBidRef.current = selectedRow?.bid;
    }

    if (selectedRow?.ask !== prevAskRef.current) {
      setAskColor(
        selectedRow?.ask > prevAskRef.current ? "text-blue-400" : "text-red-400"
      );
      prevAskRef.current = selectedRow?.ask;
    }
  }, [selectedRow?.bid, selectedRow?.ask]);

  return (
    <div>
      {isOpenpMarket ? (
        !showMessage ? (
          <Dialog
            visible={visible}
            style={{
              width: "300px",
              height: "50vh",
              position: "absolute",
              top: "40px",
              left: "40px",
              zIndex: "1000",
            }}
            onHide={() => {
              setVisible(false);
              setIsOpenMarket(true);
              setSelectedWay(null);
              setVolume(0.01);
              setStopLoss(0);
              setTakeProfit(0);
              setComment("");
              setFirstTackProfitClick(true);
              setFirstStopLossClick(true);
              setDisableSellButton(false);
            }}
            header={
              <div className="flex flex-column">
                <Dropdown
                  value={selectedWay}
                  onChange={(e) => {
                    setSelectedWay(e.value);
                    if (
                      e.value.name === "Buy Stop Limit" ||
                      e.value.name === "Sell Stop Limit"
                    ) {
                      setBuySellLimitBoxShow(true);
                    }
                  }}
                  options={exchangeWay}
                  optionLabel="name"
                  placeholder="Exchange Way"
                  className="w-16rem"
                />
              </div>
            }
          >
            <div className="flex flex-column">
              <div className="flex flex-column mt-3">
                <div className="flex justify-content-between text-xs">
                  <div className="text-900">Volume</div>
                  <div className="text-900">1 000.00 EUR</div>
                </div>

                <div className="p-inputgroup border-1 border-300 mt-1 text-xs w-full h-2rem">
                  <Button
                    icon="pi pi-minus"
                    className="border-none bg-white small-icon"
                    style={{ color: "var(--surface-800)", width: "1.357rem" }}
                    onClick={() => handleNum("volume", "decrease")}
                  />
                  <InputNumber
                    value={volume}
                    onValueChange={(e) => setVolume(e.value)}
                    className="flex align-items-center justify-content-center inputBox"
                  />
                  <Button
                    icon="pi pi-plus"
                    className="border-none bg-white small-icon"
                    style={{ color: "var(--surface-800)", width: "1.357rem" }}
                    onClick={() => handleNum("volume", "increase")}
                  />
                </div>
              </div>

              {/* <div className="flex mt-3">
              <div className="flex flex-row">
                <div className="flex flex-column mr-4 text-xs">
                  <div className="flex text-900">Price</div>

                  <div className="p-inputgroup border-1 border-300 mt-1 h-2rem">
                    <Button
                      icon="pi pi-minus"
                      className="border-none bg-white small-icon"
                      style={{ color: "var(--surface-800)" }}
                      onClick={() => handleNum("price", "decrease")}
                    />
                    <InputNumber
                      value={price}
                      onValueChange={(e) => setPrice(e.value)}
                      className="flex align-items-center justify-content-center inputBox"
                    />
                    <Button
                      icon="pi pi-plus"
                      className="border-none bg-white small-icon"
                      style={{ color: "var(--surface-800)" }}
                      onClick={() => handleNum("price", "increase")}
                    />
                  </div>
                </div>

                <div className="flex flex-column text-xs">
                  <div className="flex text-900">Stop Limit Price</div>

                  <div className="p-inputgroup border-1 border-300 mt-1 h-2rem">
                    <Button
                      icon="pi pi-minus"
                      className="border-none bg-white small-icon"
                      style={{ color: "var(--surface-800)" }}
                      onClick={() => handleNum("stopLimitPrice", "decrease")}
                    />
                    <InputNumber
                      value={stopLimitPrice}
                      onValueChange={(e) => setStopLimitPrice(e.value)}
                      className="flex align-items-center justify-content-center inputBox"
                    />
                    <Button
                      icon="pi pi-plus"
                      className="border-none bg-white small-icon"
                      style={{ color: "var(--surface-800)" }}
                      onClick={() => handleNum("stopLimitPrice", "increase")}
                    />
                  </div>
                </div>
              </div>
            </div> */}

              <div className="flex mt-3">
                <div className="flex flex-row">
                  <div className="flex flex-column mr-4 text-xs">
                    <div className="flex text-900">Stop Loss</div>

                    <div className="p-inputgroup border-1 border-300 mt-1 h-2rem">
                      <Button
                        icon="pi pi-minus"
                        className="border-none bg-white small-icon"
                        style={{
                          color: "var(--surface-800)",
                          width: "1.357rem",
                        }}
                        onClick={() => handleNum("stopLoss", "decrease")}
                      />
                      <InputNumber
                        value={stopLoss}
                        onValueChange={(e) => setStopLoss(e.value)}
                        minFractionDigits={5}
                        maxFractionDigits={5}
                        className="flex align-items-center justify-content-center inputBox"
                      />
                      <Button
                        icon="pi pi-plus"
                        className="border-none bg-white small-icon"
                        style={{
                          color: "var(--surface-800)",
                          width: "1.357rem",
                        }}
                        onClick={() => handleNum("stopLoss", "increase")}
                      />
                    </div>
                  </div>

                  <div className="flex flex-column text-xs">
                    <div className="flex text-900">Take Profit</div>

                    <div className="p-inputgroup border-1 border-300 mt-1 h-2rem">
                      <Button
                        icon="pi pi-minus"
                        className="border-none bg-white small-icon"
                        style={{
                          color: "var(--surface-800)",
                          width: "1.357rem",
                        }}
                        onClick={() => handleNum("takeProfit", "decrease")}
                      />
                      <InputNumber
                        value={takeProfit}
                        onValueChange={(e) => setTakeProfit(e.value)}
                        minFractionDigits={5}
                        maxFractionDigits={5}
                        className="flex align-items-center justify-content-center inputBox"
                      />
                      <Button
                        icon="pi pi-plus"
                        className="border-none bg-white small-icon"
                        style={{
                          color: "var(--surface-800)",
                          width: "1.357rem",
                        }}
                        onClick={() => handleNum("takeProfit", "increase")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-column mt-3 text-xs">
                <div className="flex mb-1 text-900">Comment</div>
                <InputText
                  value={comment}
                  className="h-2rem"
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>

              <div className="flex mt-2">
                <div
                  className="flex align-items-center justify-content-center flex-column"
                  style={{ width: "100%" }}
                >
                  <div className="flex flex-row bg-blue-50 mb-1">
                    <div
                      className={`flex align-items-center justify-content-center w-8rem h-2rem ${bidColor}`}
                      style={{ marginRight: "1px" }}
                    >
                      {selectedRow?.bid}
                    </div>

                    <div className="flex align-items-center justify-content-center text-300">
                      |
                    </div>

                    <div
                      className={`flex align-items-center justify-content-center w-8rem h-2rem ${askColor}`}
                      style={{ marginRight: "1px" }}
                    >
                      {selectedRow?.ask}
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <Button
                      className="border-none font-normal w-8rem"
                      style={{
                        backgroundColor: !disableSellButton
                          ? "red"
                          : "var(--red-400)",
                        marginRight: "0.5px",
                      }}
                      label="Sell"
                      onClick={() => handleOrderSell("Sell")}
                      disabled={disableSellButton}
                    />
                    <Button
                      className="border-none font-normal w-8rem"
                      style={{ backgroundColor: "blue" }}
                      label="Buy"
                      onClick={() => handleOrderBuy("Buy")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        ) : (
          <Dialog
            visible={visible}
            style={{
              width: "300px",
              height: "50vh",
              position: "absolute",
              top: "40px",
              left: "40px",
              zIndex: "1000",
            }}
            onHide={() => {
              setVisible(false);
              setShowMessage(false);
            }}
          >
            <div className="flex flex-column w-full">
              <div className="flex flex-column align-items-center mt-6">
                <div className="flex align-items-center justify-content-center font-medium text-red-500	mt-2">
                  {message}
                </div>
              </div>
              <div className="flex align-content-end w-full mt-5">
                <Button
                  label="OK"
                  severity="warning"
                  className="w-full"
                  onClick={() => {
                    setVisible(false);
                    setShowMessage(false);
                  }}
                />
              </div>
            </div>
          </Dialog>
        )
      ) : (
        <Dialog
          visible={visible}
          style={{
            width: "300px",
            height: "50vh",
            position: "absolute",
            top: "40px",
            left: "40px",
            zIndex: "1000",
          }}
          onHide={() => {
            setVisible(false);
            setIsOpenMarket(true);
          }}
          header={
            <div
              className="flex align-items-center justify-content-center"
              style={{ marginLeft: "15px" }}
            >
              {recordStatus && (
                <div class="flex align-items-center justify-content-center font-bold border-round m-2">
                  {recordStatus?.fullSymbol}
                </div>
              )}
            </div>
          }
        >
          <div
            className="flex flex-column w-full"
            // style={{ position: "relative" }}
          >
            <div
              className="flex flex-column align-items-center mt-6"
              // style={{ height: "50vh" }}
            >
              <div className="flex align-items-center justify-content-center">
                {recordStatus?.tradestypes} {recordStatus?.volume}{" "}
                {recordStatus?.fullSymbol} at {recordStatus?.orginalPrice}
              </div>
              <div className="flex align-items-center justify-content-center font-medium text-red-500	mt-2">
                Market Closed
              </div>
            </div>
            <div
              className="flex align-content-end w-full mt-5"
              // style={{ position: "absolute", bottom: "0" }}
            >
              <Button
                label="OK"
                severity="warning"
                className="w-full"
                onClick={() => {
                  setVisible(false);
                  setIsOpenMarket(true);
                }}
              />
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}
