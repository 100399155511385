import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useData } from "../../context/DataContext";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode, FilterOperator } from "primereact/api"; // Import this for filter match modes
import { Paginator } from "primereact/paginator"; // Import the Paginator component
import "./PriceTable.css";

// Custom hook to throttle API requests (optimized fetching)
const useThrottle = (callback, delay) => {
  const lastCall = useRef(0);

  useEffect(() => {
    const handle = setInterval(() => {
      const now = Date.now();
      if (now - lastCall.current > delay) {
        callback();
        lastCall.current = now;
      }
    }, delay);

    return () => clearInterval(handle);
  }, [callback, delay]);
};

export function PriceTable({
  setNewOrderDialogVisible,
  setVisible,
  setSelectedItemSymbol,
  mobile,
}) {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // Total records for pagination
  const [prevBidValues, setPrevBidValues] = useState({});
  const [prevAskValues, setPrevAskValues] = useState({});
  const [symbolForexList, setSymbolForexList] = useState([]);
  const [symbolCryptoList, setSymbolCryptoList] = useState([]);
  const [symbolStocksList, setSymbolStocksList] = useState([]);
  const [symbolMetalsList, setSymbolMetalsList] = useState([]);
  const [symbolIndicesList, setSymbolIndicesList] = useState([]);
  // Pagination state
  const [first, setFirst] = useState(0); // First row of the current page
  const [rows, setRows] = useState(!mobile ? 10 : 15); // Number of rows per page
  const { selectedRow, setSelectedRow, setQuote, serverURL, setSymbolType } =
    useData();
  const [isOpen, setIsOpen] = useState("open");
  const [loading, setLoading] = useState(false);
  const [foundValue, setFoundValue] = useState(false);
  const tradeType = [
    { name: "Forex", code: "F" },
    { name: "Crypto", code: "C" },
    { name: "Stocks", code: "S" },
    { name: "Metals", code: "M" },
    { name: "Indices", code: "I" },
  ];
  const [selectedTradeType, setSelectedTradeType] = useState(tradeType[0]);
  const startDateValue = new Date();
  const startDate = new Date(
    startDateValue.setDate(startDateValue.getDate() - 1)
  )
    .toISOString()
    .split("T")[0];
  const endDate = new Date().toISOString().split("T")[0];

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    Symbol: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
    setFirst(0); // Reset to the first page on search
    // fetchData(); // Trigger data fetch immediately after filter change
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
        <Dropdown
          value={selectedTradeType}
          onChange={async (e) => {
            setLoading(true);
            setSelectedTradeType(e.value);
            setSymbolType(e.value);
            setTableData([]);
            setSelectedRow();
          }}
          options={tradeType}
          optionLabel="name"
          placeholder="Select a Type"
        />
      </div>
    );
  };

  const header = renderHeader();

  // Fetch symbol list once
  const fetchSymbolList = async () => {
    try {
      const response = await fetch(
        `${serverURL}/platform/getMainCurrencies.php`
      );
      const data = await response.json();
      const forexSymbols = [];
      const cryptoSymbols = [];
      const stocksSymbols = [];
      const metalsSymbols = [];
      const indicesSymbols = [];

      data.data.forEach((item) => {
        if (item.CurrencyType === "Forex") {
          forexSymbols.push(item);
        } else if (item.CurrencyType === "Crypto") {
          cryptoSymbols.push(item);
        } else if (item.CurrencyType === "Stocks") {
          stocksSymbols.push(item);
        } else if (item.CurrencyType === "Metals") {
          metalsSymbols.push(item);
        } else if (item.CurrencyType === "Indices") {
          indicesSymbols.push(item);
        }
      });
      setSymbolForexList(forexSymbols); // Set Forex symbols
      setSymbolCryptoList(cryptoSymbols); // Set Crypto symbols
      setSymbolStocksList(stocksSymbols);
      setSymbolMetalsList(metalsSymbols);
      setSymbolIndicesList(indicesSymbols);
    } catch (error) {
      console.error("Error fetching symbol list: ", error);
    }
  };

  useEffect(async () => {
    fetchSymbolList(); // Fetch symbols on component mount
  }, []);

  // Centralized function to update previous bid/ask values
  const updatePreviousValues = (symbolId, newBid, newAsk) => {
    setPrevBidValues((prev) => ({
      ...prev,
      [symbolId]: newBid,
    }));

    setPrevAskValues((prev) => ({
      ...prev,
      [symbolId]: newAsk,
    }));
  };
  const fetchSymbolData = async (symbol) => {
    let apiURL = "";

    if (symbol.CurrencyType === "Forex") {
      apiURL = `https://api.polygon.io/v1/conversion/USD/${symbol.Symbol}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;
    } else if (symbol.CurrencyType === "Crypto") {
      apiURL = `https://api.binance.com/api/v3/ticker/bookTicker?symbol=${symbol.Symbol}USDT`;
    } else if (symbol.CurrencyType === "Stocks") {
      apiURL = `https://api.polygon.io/v2/last/nbbo/${symbol.Symbol}?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;
    } else if (symbol.CurrencyType === "Metals") {
      apiURL = `https://api.polygon.io/v1/conversion/USD/${symbol.Symbol}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;
    } else if (symbol.CurrencyType === "Indices") {
      apiURL = `https://api.polygon.io/v2/aggs/ticker/I:${symbol.Symbol}/range/1/second/${startDate}/${endDate}?sort=desc&limit=1&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;
    }

    try {
      const response = await fetch(apiURL);
      const responseData = await response.json();
      // Extract newAsk and newBid based on CurrencyType
      let newAsk, newBid;
      if (symbol.CurrencyType === "Forex") {
        newAsk = responseData.last.ask.toFixed(5);
        newBid = responseData.last.bid.toFixed(5);
      } else if (symbol.CurrencyType === "Crypto") {
        newAsk = responseData.askPrice;
        newBid = responseData.bidPrice;
      } else if (symbol.CurrencyType === "Stocks") {
        newAsk = responseData.results.P.toFixed(5);
        newBid = responseData.results.p.toFixed(5);
      } else if (symbol.CurrencyType === "Metals") {
        newAsk = responseData.last.ask.toFixed(5);
        newBid = responseData.last.bid.toFixed(5);
      } else if (symbol.CurrencyType === "Indices") {
        newAsk = responseData.results[0].h.toFixed(5);
        newBid = responseData.results[0].l.toFixed(5);
      }

      // Set previous ask/bid or use the current as default if not present
      const prevAsk = prevAskValues[symbol.ID] || newAsk;
      const prevBid = prevBidValues[symbol.ID] || newBid;

      // Determine icon styles based on previous and current values
      const iconBidClass =
        newBid >= prevBid ? "pi pi-sort-up-fill" : "pi pi-sort-down-fill";
      const iconBidColor = newBid >= prevBid ? "green" : "red";
      const iconAskColor = newAsk >= prevAsk ? "green" : "red";

      // Update previous values
      updatePreviousValues(symbol.ID, newBid, newAsk);

      return {
        ...symbol,
        ask: parseFloat(newAsk).toFixed(5),
        bid: parseFloat(newBid).toFixed(5),
        iconBidClass,
        iconBidColor,
        iconAskColor,
      };
    } catch (error) {
      console.error("Error fetching data for symbol: ", symbol.Symbol, error);
      return {
        symbol: symbol.Symbol,
        ask: "Error",
        bid: "Error",
        exchange: "N/A",
        iconBidClass: "pi pi-times-circle",
        iconBidColor: "gray",
        iconAskColor: "gray",
      };
    }
  };

  const fetchData = async () => {
    if (!globalFilterValue) {
      // Determine the list of symbols to use based on the selected trade type
      const selectedList =
        selectedTradeType.name === "Forex"
          ? symbolForexList
          : selectedTradeType.name === "Crypto"
          ? symbolCryptoList
          : selectedTradeType.name === "Metals"
          ? symbolMetalsList
          : selectedTradeType.name === "Indices"
          ? symbolIndicesList
          : symbolStocksList;

      const valueLoop = selectedList.slice(first, first + rows);

      // Fetch data for each symbol in the selected list
      const dataPromises = valueLoop.map(async (symbol) =>
        fetchSymbolData(symbol)
      );
      const fetchedData = await Promise.all(dataPromises);
      setTableData(fetchedData);
      setFoundValue(true);
      setTotalRecords(selectedList.length);
    } else {
      const selectedList =
        selectedTradeType.name === "Forex"
          ? symbolForexList
          : selectedTradeType.name === "Crypto"
          ? symbolCryptoList
          : selectedTradeType.name === "Metals"
          ? symbolMetalsList
          : selectedTradeType.name === "Indices"
          ? symbolIndicesList
          : symbolStocksList;

      // Filter symbols based on the global filter value
      const currentPageSymbols = selectedList.slice(first, first + rows);
      const filteredInPage = currentPageSymbols.filter((symbol) =>
        symbol.Symbol.toLowerCase().includes(globalFilterValue.toLowerCase())
      );

      if (filteredInPage && filteredInPage.length > 0) {
        const dataPromises = filteredInPage.map(async (symbol) =>
          fetchSymbolData(symbol)
        );
        const fetchedData = await Promise.all(dataPromises);

        setTableData(fetchedData);
        setFoundValue(true);
        setTotalRecords(filteredInPage.length);
      } else {
        const filteredSymbol = selectedList.filter((symbol) =>
          symbol.Symbol.toLowerCase().includes(globalFilterValue.toLowerCase())
        );

        if (filteredSymbol && filteredSymbol.length > 0) {
          const dataPromises = filteredSymbol.map(async (symbol) =>
            fetchSymbolData(symbol)
          );
          const fetchedData = await Promise.all(dataPromises);

          setTableData(fetchedData);
          setFoundValue(true);
          setTotalRecords(filteredSymbol.length);
        } else {
          setTableData([]);
          setFoundValue(false);
          setTotalRecords(0);
        }
      }
    }
  };

  const selectedRowData = async () => {
    if (!selectedRow && tableData.length > 0) {
      // Set selectedRow only if it hasn't been set already (i.e., on initial load)
      const firstSelected = tableData[0];
      setSelectedRow(firstSelected); // Set to first item in the table
      // setSelectedItemSymbol(selectedRow);
      setQuote(firstSelected?.Symbol); // Set the quote to the first item's symbol
    } else if (selectedRow) {
      // Persist the selected row value (do not reset on page change)
      const selectedRowData = tableData.find(
        (customer) => customer.ID === selectedRow.ID
      );
      await setSelectedRow(selectedRowData || selectedRow); // Keep selected row as is
      // setSelectedItemSymbol(selectedRowData || selectedRow);
      await setQuote(selectedRow?.Symbol); // Keep the quote
    }
  };

  useEffect(async () => {
    await fetchData();
    await selectedRowData();
    setLoading(false);
  }, [selectedTradeType]); // Update data on pagination change

  useEffect(() => {
    if (isOpen === "close") {
      fetchData();
    }
  }, [isOpen]);

  useThrottle(
    () => {
      if (!loading) {
        fetchData();
        selectedRowData();
      }
    },
    isOpen === "open" ? 1000 : 60000
  );

  // useEffect(() => {
  //   if (!selectedRow && tableData.length > 0) {
  //     // Set selectedRow only if it hasn't been set already (i.e., on initial load)
  //     const firstSelected = tableData[0];
  //     setSelectedRow(firstSelected); // Set to first item in the table
  //     // setSelectedItemSymbol(selectedRow);
  //     setQuote(firstSelected?.Symbol); // Set the quote to the first item's symbol
  //   } else if (selectedRow) {
  //     // Persist the selected row value (do not reset on page change)
  //     const selectedRowData = tableData.find(
  //       (customer) => customer.ID === selectedRow.ID
  //     );
  //     setSelectedRow(selectedRowData || selectedRow); // Keep selected row as is
  //     // setSelectedItemSymbol(selectedRowData || selectedRow);
  //     setQuote(selectedRow?.Symbol); // Keep the quote
  //   }
  // }, [tableData, selectedRow]);

  const onRowSelect = (event) => {
    setSelectedRow(event.data);
    if (mobile) {
      setVisible(true);
      setSelectedItemSymbol(event.data);
    }
  };

  const onRowDoubleClick = (event) => {
    setSelectedRow(event.data);
    if (!mobile) {
      setNewOrderDialogVisible(true);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      ) : !tableData.length && foundValue ? (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <DataTable
            value={tableData}
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            paginator
            paginatorTemplate="PrevPageLink PageLinks NextPageLink"
            totalRecords={totalRecords}
            lazy
            first={first}
            rows={rows}
            onPage={async (event) => {
              setLoading(true);
              setTableData([]);
              setFirst(event.first);
              setRows(event.rows);
              await fetchData(); // Fetch new data when page changes
              setTableData([]);
              setLoading(false);
            }}
            size={"small"}
            loading={loading}
            selectionMode="single"
            dataKey="Symbol"
            header={header}
            filters={filters}
            globalFilterFields={["Symbol"]}
            onFilter={(e) => setFilters(e.filters)}
            emptyMessage="No data found."
            tableStyle={{ fontSize: "0.8rem" }}
            scrollable
            onRowSelect={onRowSelect}
            onRowDoubleClick={onRowDoubleClick}
          >
            <Column
              header=""
              style={{ width: "40px" }}
              bodyStyle={{ width: "40px" }}
              body={(rowData) => (
                <span
                  className={rowData.iconBidClass}
                  style={{ color: rowData.iconBidColor, fontSize: "12px" }}
                ></span>
              )}
            ></Column>

            <Column
              field="Symbol"
              header="Symbol"
              body={(rowData) => `USD${rowData.Symbol}`}
              className="font-medium"
            />
            <Column
              field="bid"
              header="Bid"
              className="font-medium"
              body={(rowData) => (
                <div style={{ color: rowData.iconBidColor }}>{rowData.bid}</div>
              )}
            ></Column>
            <Column
              field="ask"
              header="Ask"
              className="font-medium"
              body={(rowData) => (
                <div style={{ color: rowData.iconAskColor }}>{rowData.ask}</div>
              )}
            ></Column>
          </DataTable>
        </>
      )}
    </div>
  );
}
