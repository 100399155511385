import React, { useState, useEffect, useRef } from "react";
import { useData } from "../../context/DataContext";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";

export function TradeTableMobile({
  setVisible,
  setSelectedItem,
  loading,
  profitValue,
  TotalBalance,
  FreeMargin,
  Equity,
  Level,
  Margin,
  data,
}) {
  // const fetchMarketStatus = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://api.polygon.io/v1/marketstatus/now?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
  //     );
  //     const data = await response.json();
  //     setIsOpen(data?.market);
  //   } catch (error) {
  //     console.error("Error fetching symbol list: ", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchMarketStatus();
  // }, []);

  return (
    <div>
      {loading ? (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      ) : (
        <div className="flex flex-column">
          <div
            className="flex align-items-center justify-content-center h-3rem font-semibold text-sm"
            style={{ color: profitValue > 0 ? "blue" : "red" }}
          >
            {isNaN(profitValue) ? " " : profitValue} USD
          </div>
          <div className="flex border-bottom-2 text-300 mb-1"></div>
          <div className="flex flex-column m-1">
            <div className="flex flex-row mb-2">
              <div className="flex flex-row w-5">
                <div className="flex w-5 text-sm text-color-secondary font-medium">
                  Balance:
                </div>
                <div className="flex">{TotalBalance.toFixed(4)}</div>
              </div>
              <div className="flex flex-row w-6">
                <div className="flex w-7 text-sm text-color-secondary font-medium">
                  Free margin:
                </div>
                <div className="flex">{FreeMargin}</div>
              </div>
            </div>

            <div className="flex flex-row mb-2">
              <div className="flex flex-row w-5">
                <div className="flex w-5 text-sm text-color-secondary font-medium">
                  Equity:
                </div>
                <div className="flex">{Equity}</div>
              </div>
              <div className="flex flex-row w-6">
                <div className="flex w-7 text-sm text-color-secondary font-medium">
                  Level:
                </div>
                <div className="flex">{Level}</div>
              </div>
            </div>

            <div className="flex flex-row w-5 mb-2">
              <div className="flex w-5 text-sm text-color-secondary font-medium">
                Margin:
              </div>
              <div className="flex">{Margin}</div>
            </div>
          </div>

          {data.length > 0 && (
            <div className="flex h-2rem bg-gray-200">
              <div className="flex m-1 text-sm text-color-secondary font-medium">
                Positions
              </div>
            </div>
          )}

          <div
            className="flex flex-column"
            style={{ overflow: "auto", maxHeight: "470px" }}
          >
            {data.map((value, index) => (
              <div
                key={index}
                className={`flex flex-row justify-content-between p-1 mt-2 mr-2 ml-2 mb-1 border-round ${
                  data.color === "blue" ? "bg-primary-50" : "bg-red-50"
                }`}
                onClick={() => {
                  setVisible(true);
                  setSelectedItem(value);
                }}
              >
                <div className="flex flex-column">
                  <div className="flex flex-row">
                    <div className="flex mr-1 font-medium">{value.symbol}</div>
                    <div
                      className="flex mr-1 text-sm align-items-center"
                      style={{
                        color: data.color === "blue" ? "blue" : "red",
                      }}
                    >
                      {value.type.toLowerCase()} {value.volume}
                    </div>
                  </div>
                  <div className="flex flex-row text-sm">
                    <div className="flex mr-1">{value.orginalPrice}</div>
                    <div className="flex mr-1 ml-1 mt-1 align-items-center">
                      <i
                        className="fa-solid fa-arrow-right-long"
                        style={{
                          fontSize: "11px",
                        }}
                      ></i>
                    </div>
                    <div className="flex">
                      {value.type === "Buy" ? value.bid : value.ask}
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-column"
                  style={{
                    color: data.color === "blue" ? "blue" : "red",
                  }}
                >
                  {value.profit}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
