import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { ProgressSpinner } from "primereact/progressspinner";
import { useData } from "../../context/DataContext";
import axios from "axios";

const useInterval = (callback, delay) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);

  return intervalRef;
};

export function TradeTable({ setNewOrderDialogVisible }) {
  const [profitValue, setProfitValue] = useState(0);
  const [equityValue, setEquityValue] = useState(0);
  const [marginValue, setMarginValue] = useState(0);
  const [freeMarginValue, setFreeMarginValue] = useState(0);
  const [levelValue, setLevelValue] = useState(0);
  const [TotalBalance, setTotoalBalance] = useState(0);
  const { addRecord, setFreeMarginPublic, setFreeMarginLevelPublic } =
    useData();
  const startDateValue = new Date();
  const startDate = new Date(
    startDateValue.setDate(startDateValue.getDate() - 1)
  )
    .toISOString()
    .split("T")[0];
  const endDate = new Date().toISOString().split("T")[0];
  const [deleteCheck, setDeleteCheck] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [isOpen, setIsOpen] = useState(null);
  const [isOpen, setIsOpen] = useState("open");

  // const fetchMarketStatus = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://api.polygon.io/v1/marketstatus/now?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
  //     );
  //     const data = await response.json();
  //     setIsOpen(data?.market);
  //   } catch (error) {
  //     console.error("Error fetching symbol list: ", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchMarketStatus();
  // }, []);

  const fetchDataValue = async (symbol, currencyType) => {
    const apiURL =
      currencyType === "Forex"
        ? `https://api.polygon.io/v1/conversion/USD/${symbol.substring(
            3
          )}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : currencyType === "Crypto"
        ? `https://api.binance.com/api/v3/ticker/bookTicker?symbol=${symbol}USDT`
        : currencyType === "Metals"
        ? `https://api.polygon.io/v1/conversion/USD/${symbol}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : currencyType === "Indices"
        ? `https://api.polygon.io/v2/aggs/ticker/I:${symbol}/range/1/second/${startDate}/${endDate}?sort=desc&limit=1&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : `https://api.polygon.io/v2/last/nbbo/${symbol}?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;
    try {
      const response = await fetch(apiURL);
      const responseData = await response.json();

      return {
        ask:
          currencyType === "Forex"
            ? responseData.last.ask.toFixed(5)
            : currencyType === "Crypto"
            ? parseFloat(responseData.askPrice).toFixed(5)
            : currencyType === "Metals"
            ? responseData.last.ask.toFixed(5)
            : currencyType === "Indices"
            ? responseData.results[0].h.toFixed(5)
            : responseData.results.P.toFixed(5),
        bid:
          currencyType === "Forex"
            ? responseData.last.bid.toFixed(5)
            : currencyType === "Crypto"
            ? parseFloat(responseData.bidPrice).toFixed(5)
            : currencyType === "Metals"
            ? responseData.last.bid.toFixed(5)
            : currencyType === "Indices"
            ? responseData.results[0].l.toFixed(5)
            : responseData.results.p.toFixed(5),
      };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { ask: null, bid: null };
    }
  };

  const fetchProfitDetails = async (ticketNumber) => {
    try {
      const response = await fetch(
        "https://makassab.com/trading_platform/backend/platform/getProfit.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ticketNumber }),
        }
      );

      const result = await response.json();
      if (result.status === "success") {
        return { lots: result.lots, leverage: result.leverage };
      } else {
        console.error("Error fetching profit details:", result);
        return { lots: 0, leverage: 0 }; // Fallback in case of failure
      }
    } catch (error) {
      console.error("Fetch error:", error);
      return { lots: 0, leverage: 0 }; // Fallback in case of error
    }
  };

  const fetchData = async () => {
    try {
      const updatedData = await Promise.all(
        data.map(async (data) => {
          // Run fetchDataValue and fetchProfitDetails concurrently
          const [dataValues, profitDetails] = await Promise.all([
            fetchDataValue(data.symbol, data.currencyType),
            fetchProfitDetails(data.ticket),
          ]);

          const { ask, bid } = dataValues;
          const { lots, leverage } = profitDetails;

          const profit =
            data.type === "Buy"
              ? (bid - data?.orginalPrice) * lots * leverage * data?.volume
              : (data?.orginalPrice - ask) * lots * leverage * data?.volume;

          // Check if bid is equal to stoploss or takeprofit
          if (bid && data) {
            if (bid <= data.stopLoss || bid <= data.takeProfit) {
              await handleDelete({
                ...data,
                ask: 0,
                bid: bid,
                profit: isNaN(profit) ? "" : profit.toFixed(5),
              });
              return null; // Skip returning the data
            }
          }

          return {
            ...data,
            ask: data.type === "Sell" ? ask : data.orginalPrice,
            bid: data.type === "Buy" ? bid : data.orginalPrice,
            profit: isNaN(profit) ? "" : profit.toFixed(5),
          };
        })
      );

      // Filter out any null values returned due to stoploss/takeprofit triggering deletion
      setData(updatedData.filter((item) => item !== null));
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  };

  const fetchRecords = async () => {
    // setLoading(true); // Set loading to true before fetching records

    try {
      const response = await axios.get(
        "https://makassab.com/trading_platform/backend/platform/getTrades.php"
      );

      if (response.data.status === "success") {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      // setLoading(false); // Set loading to false after data is fetched
    }
  };

  const fetchProfileDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://makassab.com/trading_platform/backend/dashboard/customer/getProfileDetails.php"
      );
      setTotoalBalance(response.data.data[0].totalBalance);
    } catch (error) {
      console.error("Error fetching profile details:", error);
    } finally {
      setLoading(false); // Data fetched successfully, set loading to false
    }
  };

  const fetchMoneyDetails = async () => {
    try {
      const response = await axios.get(
        "https://makassab.com/trading_platform/backend/platform/getMargins.php"
      );
      if (response.data.status === "success") {
        let margin = response.data.totalMargin;
        margin = margin.toFixed(4);
        setMarginValue(margin);
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    const calculateProfit = async () => {
      try {
        // Fetch lots and leverage for all items in data
        const profitPromises = data.map(async (value) => {
          const { lots, leverage } = await fetchProfitDetails(value.ticket);

          // Calculate the profit for each entry based on the fetched lots and leverage
          const profit =
            value.type === "Buy"
              ? (value.bid - value.orginalPrice) *
                lots *
                leverage *
                value.volume
              : (value.orginalPrice - value.ask) *
                lots *
                leverage *
                value.volume;

          return profit;
        });

        // Resolve all promises and calculate the total profit
        const allProfits = await Promise.all(profitPromises);
        const updatedProfit = allProfits.reduce(
          (acc, profit) => acc + profit,
          0
        );
        setProfitValue(updatedProfit.toFixed(4));
        let calculatedEquity = TotalBalance + updatedProfit;
        setEquityValue(calculatedEquity ? calculatedEquity.toFixed(4) : 0);

        let calculationFreeMargin = calculatedEquity - marginValue;
        setFreeMarginValue(
          calculationFreeMargin ? calculationFreeMargin.toFixed(4) : 0
        );
        setFreeMarginPublic(calculationFreeMargin.toFixed(4));

        // Check if calculatedEquity and marginValue are valid numbers
        if (calculatedEquity > 0 && marginValue > 0) {
          let level = ((calculatedEquity / marginValue) * 100).toFixed(2);
          setLevelValue(level);
          setFreeMarginLevelPublic(level);
        } else {
          setLevelValue(0);
          console.error("Invalid value for calculatedEquity or marginValue");
        }
      } catch (error) {
        console.error("Error calculating profit:", error);
      }
    };

    calculateProfit();
    // }, [data, addRecord]);
  }, [data]);

  useEffect(async () => {
    setLoading(true);
    await Promise.all([
      fetchRecords(),
      fetchProfileDetails(),
      fetchMoneyDetails(),
    ]);
    setLoading(false);
  }, [addRecord, deleteCheck]);

  // Use the useInterval hook at the top level of the component
  useInterval(
    () => {
      if (!loading) {
        fetchData();
      }
    },
    isOpen !== "close" ? 1500 : null,
    loading
  );

  useEffect(() => {
    if (isOpen === "close") {
      fetchData(); // Fetch data once when component mounts or symbol list changes
    }
  }, [isOpen]);

  const handleDelete = async (orderToDelete) => {
    setLoading(true);
    // Optimistically update the state
    const updatedData = data.filter(
      (record) => record.ticket !== orderToDelete.ticket
    );
    setData(updatedData);

    let finalBidValue = orderToDelete?.bid;
    let finalAskValue = orderToDelete?.ask;

    const deletedRecord = {
      ticket: orderToDelete.ticket,
      lastPrice: orderToDelete.type === "Buy" ? finalBidValue : finalAskValue,
      profit: orderToDelete.profit,
    };

    try {
      const response = await fetch(
        "https://makassab.com/trading_platform/backend/platform/updateTrades.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(deletedRecord),
        }
      );

      const data = await response.json();

      if (data.status !== "success") {
        // If the server request fails, revert the state change
        setData((prevData) => [...prevData, orderToDelete]);
      } else {
        setDeleteCheck(!deleteCheck);
        fetchRecords();
      }

      setLoading(false);
    } catch (error) {
      console.error("Error updating trades:", error);
      // Revert the state change if there's an error
      setData((prevData) => [...prevData, orderToDelete]);
    }
  };

  const Balance = () => (
    <div className="flex justify-content-start text-xs">
      <div className="flex">Balance: </div>
      <div className="flex ml-1">{TotalBalance.toFixed(4)}</div>
    </div>
  );

  const Equity = () => (
    <div className="flex justify-content-start text-xs">
      <div className="flex">Equity: </div>
      <div className="flex ml-1">{equityValue}</div>
    </div>
  );

  const Margin = () => (
    <div className="flex justify-content-start text-xs">
      <div className="flex">Margin: </div>
      <div className="flex ml-1">{marginValue}</div>
    </div>
  );

  const FreeMargin = () => (
    <div className="flex justify-content-start text-xs">
      <div className="flex">Free margin: </div>
      <div className="flex ml-1">{freeMarginValue}</div>
    </div>
  );

  const Level = () => (
    <div className="flex justify-content-start text-xs">
      <div className="flex">Level: </div>
      <div className="flex ml-1">{levelValue}%</div>
    </div>
  );

  const closeButtonTemplate = (rowData) => (
    <Button
      icon="pi pi-times"
      rounded
      text
      severity="secondary"
      aria-label="Bookmark"
      style={{ fontSize: "8px", height: "8px", boxShadow: "none" }}
      onClick={() => {
        if (isOpen !== "closed") {
          handleDelete(rowData);
        }
      }}
    />
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer={Balance} colSpan={1} />
        <Column footer={Equity} />
        <Column footer={Margin} />
        <Column footer={FreeMargin} />
        <Column footer={Level} />
        <Column footer="" />
        <Column footer="" />
        <Column footer="" />
        <Column footer="" />
        <Column footer="" />
        <Column footer="" />
        <Column footer="" />
        <Column footer="" />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="card flex flex-column" style={{ width: "100%" }}>
      {loading ? (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={data}
          footerColumnGroup={footerGroup}
          size="small"
          tableStyle={{ fontSize: "12px" }}
          emptyMessage=" "
          scrollable
          scrollHeight="24vh"
        >
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            header="Symbol"
            field="symbol"
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            field="ticket"
            header="Ticket"
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            field="timeStamp"
            header="Time"
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            field="type"
            header="Type"
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            field="volume"
            header="Volume"
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            header="Price"
            body={(rowData) => {
              const matchingValue = data?.find(
                (val) => val?.ticket === rowData?.ticket
              );
              return matchingValue && rowData.type === "Sell" ? (
                <span>{matchingValue.ask}</span>
              ) : (
                <span>{rowData.orginalPrice}</span>
              );
            }}
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            header="S / L"
            body={(rowData) =>
              rowData.stopLoss === 0 ? null : rowData.stopLoss
            }
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            header="T / P"
            body={(rowData) =>
              rowData.takeProfit === 0 ? null : rowData.takeProfit
            }
          ></Column>
          <Column
            header="Price"
            style={{ fontSize: "12px", height: "8px" }}
            field="bid"
            body={(rowData) => {
              const matchingValue = data.find(
                (val) => val?.ticket === rowData?.ticket
              );
              return matchingValue && rowData.type === "Buy" ? (
                <span>{matchingValue.bid}</span>
              ) : (
                <span>{rowData.orginalPrice}</span>
              );
            }}
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            field="swap"
            header="Swap"
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            header="Profit"
            field="profit"
            body={(rowData) => (
              <span
                style={{
                  color: rowData.profit > 0 ? "#32e315" : "red",
                }}
              >
                {rowData.profit}
              </span>
            )}
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            field="comment"
            header="Comment"
          ></Column>
          <Column
            style={{ fontSize: "12px", height: "8px" }}
            field=""
            body={closeButtonTemplate}
          />
        </DataTable>
      )}
      {data.length === 0 && (
        <div className="flex flex-column mt-4">
          <div className="flex align-items-center justify-content-center">
            You don't have any positions
          </div>
          <div className="flex align-items-center justify-content-center mt-2">
            <button
              label="Create New Order"
              style={{
                width: "140px",
                height: "30px",
                fontSize: " .875rem",
                fontWeight: "500",
                color: "var(--primary-color)",
                fontFamily: "revert-layer",
                backgroundColor: "rgb(185, 229, 255)",
                borderWidth: "0px",
              }}
              onClick={() => setNewOrderDialogVisible(true)}
            >
              Create New Order
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
